var Handlebars = require("/Users/ayselafsar/Workspace/dicomviewer/js/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "active";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <div id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" tabindex=\"1\" class=\"toolbarSectionButton "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.toolActive : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + alias4(((helper = (helper = helpers.classes || (depth0 != null ? depth0.classes : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"classes","hash":{},"data":data}) : helper)))
    + "\" title=\""
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "\">\n            <div class=\"svgContainer\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.svgClasses : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"buttonLabel\">\n                <span>"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</span>\n            </div>\n        </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                <div class=\""
    + container.escapeExpression(((helper = (helper = helpers.svgClasses || (depth0 != null ? depth0.svgClasses : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"svgClasses","hash":{},"data":data}) : helper)))
    + "\"></div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                <i class=\""
    + container.escapeExpression(((helper = (helper = helpers.iconClasses || (depth0 != null ? depth0.iconClasses : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"iconClasses","hash":{},"data":data}) : helper)))
    + "\"></i>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<div id=\"toolbar\" class=\"toolbar\">\n    <div class=\"toggleSeriesPanel\">\n        <div id=\"toggleSeriesPanelButton\" tabindex=\"1\" class=\"toolbarSectionButton roundedButton toggleSeriesPanelButton "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.seriesPanelOpen : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = helpers.classes || (depth0 != null ? depth0.classes : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"classes","hash":{},"data":data}) : helper)))
    + "\" title=\"Toggle Series Panel\">\n            <div class=\"svgContainer\">\n                <div class=\"svgContent series-panel\"></div>\n            </div>\n            <div class=\"buttonLabel\">\n                <span>Series</span>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"toolbarSectionTools\">\n"
    + ((stack1 = helpers.each.call(alias1,(helpers.toolbarButtons || (depth0 && depth0.toolbarButtons) || alias2).call(alias1,{"name":"toolbarButtons","hash":{},"data":data}),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        <div class=\"moreTools js-more-tools\">\n            <div class=\"toolbarSectionButton notTool\">\n                <div class=\"svgContainer\">\n                    <i class=\"fa fa-chevron-down\"></i>\n                </div>\n                <div class=\"buttonLabel\">\n                    <span>More</span>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <a class=\"button-close js-close-viewer\" title=\"Close Viewer\"><i class=\"fa fa-times fa-lg\"></i></a>\n</div>";
},"useData":true});