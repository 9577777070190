var Handlebars = require("/Users/ayselafsar/Workspace/dicomviewer/js/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "    <div class=\"imageControlButton imageControlUp\">\n        <i class=\"fa fa-chevron-up\"></i>\n    </div>\n    <div class=\"imageControls\">\n        <div class=\"scrollbar\">\n            <input class=\"imageSlider\" type=\"range\" min=\"1\" value=\""
    + alias2(alias1((depth0 != null ? depth0.imageIndex : depth0), depth0))
    + "\" max=\""
    + alias2(alias1((depth0 != null ? depth0.numImages : depth0), depth0))
    + "\"/>\n        </div>\n    </div>\n    <div class=\"imageControlButton imageControlDown\">\n        <i class=\"fa fa-chevron-down\"></i>\n    </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div id=\"imageControls\">\n"
    + ((stack1 = helpers["if"].call(alias1,(helpers.gt || (depth0 && depth0.gt) || helpers.helperMissing).call(alias1,(depth0 != null ? depth0.numImages : depth0),1,{"name":"gt","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true});