var Handlebars = require("/Users/ayselafsar/Workspace/dicomviewer/js/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.studies : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers.each.call(alias1,(helpers.studyThumbnails || (depth0 && depth0.studyThumbnails) || helpers.helperMissing).call(alias1,blockParams[0][0],{"name":"studyThumbnails","hash":{},"data":data,"blockParams":blockParams}),{"name":"each","hash":{},"fn":container.program(3, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("/Users/ayselafsar/Workspace/dicomviewer/js/components/templates/ImageThumbnail.html"),depth0,{"name":"ImageThumbnail","hash":{"isActiveStudy":true},"data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1;

  return "        <div class=\"accordion\" id=\"studyBrowserAccordion\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.studies : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "        </div>\n";
},"6":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing;

  return "                <div class=\"card\">\n                    <div class=\"card-header\" id=\"heading"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? stack1.patientId : stack1), depth0))
    + "\">\n                        <div data-toggle=\"collapse\" data-target=\"#collapse"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? stack1.patientId : stack1), depth0))
    + "\"\n                             aria-expanded=\"true\" aria-controls=\"collapse"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? stack1.patientId : stack1), depth0))
    + "\">\n                            <div class=\"study-item-box\">\n                                <div class=\"study-modality\">\n                                    <div class=\"study-modality-text\">\n                                        "
    + alias2((helpers.modalitiesList || (depth0 && depth0.modalitiesList) || alias4).call(alias3,blockParams[0][0],{"name":"modalitiesList","hash":{},"data":data,"blockParams":blockParams}))
    + "\n                                    </div>\n                                </div>\n                                <div class=\"study-text\">\n                                    <div class=\"study-date\">"
    + alias2((helpers.formatDA || (depth0 && depth0.formatDA) || alias4).call(alias3,((stack1 = blockParams[0][0]) != null ? stack1.studyDate : stack1),{"name":"formatDA","hash":{},"data":data,"blockParams":blockParams}))
    + "</div>\n                                    <div class=\"study-description\">"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? stack1.studyDescription : stack1), depth0))
    + "</div>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n\n                    <div id=\"collapse"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? stack1.patientId : stack1), depth0))
    + "\" class=\"collapse show\" aria-labelledby=\"heading"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? stack1.patientId : stack1), depth0))
    + "\">\n                        <div class=\"card-body\">\n"
    + ((stack1 = helpers["if"].call(alias3,(data && data.first),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams),"inverse":container.program(10, data, 0, blockParams),"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "                        </div>\n                    </div>\n                </div>\n";
},"7":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers.each.call(alias1,(helpers.studyThumbnails || (depth0 && depth0.studyThumbnails) || helpers.helperMissing).call(alias1,blockParams[1][0],{"name":"studyThumbnails","hash":{},"data":data,"blockParams":blockParams}),{"name":"each","hash":{},"fn":container.program(8, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("/Users/ayselafsar/Workspace/dicomviewer/js/components/templates/ImageThumbnail.html"),depth0,{"name":"ImageThumbnail","hash":{"isActiveStudy":true},"data":data,"indent":"                                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers.each.call(alias1,(helpers.studyThumbnails || (depth0 && depth0.studyThumbnails) || helpers.helperMissing).call(alias1,blockParams[1][0],{"name":"studyThumbnails","hash":{},"data":data,"blockParams":blockParams}),{"name":"each","hash":{},"fn":container.program(11, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("/Users/ayselafsar/Workspace/dicomviewer/js/components/templates/ImageThumbnail.html"),depth0,{"name":"ImageThumbnail","data":data,"indent":"                                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div id=\"studyBrowser\" class=\"studyBrowser\">\n    <div class=\"scrollableStudyThumbnails\">\n        "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.studies : depth0)) != null ? stack1.length : stack1), depth0))
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(helpers.eq || (depth0 && depth0.eq) || helpers.helperMissing).call(alias1,((stack1 = (depth0 != null ? depth0.studies : depth0)) != null ? stack1.length : stack1),1,{"name":"eq","hash":{},"data":data,"blockParams":blockParams}),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams),"inverse":container.program(5, data, 0, blockParams),"data":data,"blockParams":blockParams})) != null ? stack1 : "")
    + "    </div>\n</div>";
},"usePartial":true,"useData":true,"useBlockParams":true});