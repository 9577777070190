var Handlebars = require("/Users/ayselafsar/Workspace/dicomviewer/js/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "sidebar-open";
},"3":function(container,depth0,helpers,partials,data) {
    return "content-full";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div id=\"viewerMain\">\n    <!-- Toolbar -->\n"
    + ((stack1 = container.invokePartial(require("/Users/ayselafsar/Workspace/dicomviewer/js/components/templates/Toolbar.html"),depth0,{"name":"Toolbar","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    <!-- Viewport Content -->\n    <div class=\"content\">\n        <div class=\"sidebarMenu "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.seriesPanelOpen : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"></div>\n        <div class=\"mainContent "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.seriesPanelOpen : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n            <div id=\"layoutManagerTarget\">\n                <div class=\"viewportContainer\">\n                    <div class=\"removable\">\n                        <div class=\"imageViewerViewport\" unselectable=\"on\" tabindex=\"0\"></div>\n"
    + ((stack1 = container.invokePartial(require("/Users/ayselafsar/Workspace/dicomviewer/js/components/templates/ImageControls.html"),depth0,{"name":"ImageControls","data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("/Users/ayselafsar/Workspace/dicomviewer/js/components/templates/ViewportOrientationMarkers.html"),depth0,{"name":"ViewportOrientationMarkers","data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <!-- Loading View which shows the percentage of the loaded files -->\n    <div class=\"loadingViewerMain\">\n        Loading... <span id=\"loadingPercentage\">0</span>%\n    </div>\n</div>";
},"usePartial":true,"useData":true});