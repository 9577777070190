var Handlebars = require("/Users/ayselafsar/Workspace/dicomviewer/js/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression;

  return "<div class=\"thumbnailEntry\">\n    <div class=\"imageThumbnail\">\n        <div id=\"imageThumbnailCanvas"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.stack : depth0)) != null ? stack1.seriesNumber : stack1), depth0))
    + "_"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.stack : depth0)) != null ? stack1.displaySetInstanceUid : stack1), depth0))
    + "\" class=\"imageThumbnailCanvas\">\n            "
    + alias2(((helper = (helper = helpers.imageThumbnailCanvas || (depth0 != null ? depth0.imageThumbnailCanvas : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"imageThumbnailCanvas","hash":{},"data":data}) : helper)))
    + "\n        </div>\n    </div>\n    <div class=\"seriesDetails\">\n        <div class=\"seriesDescription\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.stack : depth0)) != null ? stack1.seriesDescription : stack1), depth0))
    + "</div>\n        <div class=\"seriesInformation\">\n            <div class=\"item item-series clearfix\">\n                <div class=\"icon\">S:</div>\n                <div class=\"value\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.stack : depth0)) != null ? stack1.seriesNumber : stack1), depth0))
    + "</div>\n            </div>\n            <div class=\"item item-frames clearfix\">\n                <div class=\"icon\"><div></div></div>\n                <div class=\"value\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.stack : depth0)) != null ? stack1.numImageFrames : stack1), depth0))
    + "</div>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});