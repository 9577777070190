var Handlebars = require("/Users/ayselafsar/Workspace/dicomviewer/js/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "("
    + alias4(((helper = (helper = helpers.imageIndex || (depth0 != null ? depth0.imageIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"imageIndex","hash":{},"data":data}) : helper)))
    + "/"
    + alias4(((helper = (helper = helpers.numImages || (depth0 != null ? depth0.numImages : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"numImages","hash":{},"data":data}) : helper)))
    + ")";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "<div class=\"imageViewerViewportOverlay\">\n    <div class=\"topleft dicomTag\">\n        <div id=\"patientName\">"
    + alias3((helpers.formatPN || (depth0 && depth0.formatPN) || alias2).call(alias1,(depth0 != null ? depth0.patientName : depth0),{"name":"formatPN","hash":{},"data":data}))
    + "</div>\n        <div id=\"patientId\">"
    + alias3(((helper = (helper = helpers.patientId || (depth0 != null ? depth0.patientId : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"patientId","hash":{},"data":data}) : helper)))
    + "</div>\n    </div>\n\n    <div class=\"topright dicomTag\">\n        <div id=\"studyDescription\">"
    + alias3(((helper = (helper = helpers.studyDescription || (depth0 != null ? depth0.studyDescription : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"studyDescription","hash":{},"data":data}) : helper)))
    + "</div>\n        <div id=\"studyDate\">"
    + alias3((helpers.formatDA || (depth0 && depth0.formatDA) || alias2).call(alias1,(depth0 != null ? depth0.studyDate : depth0),{"name":"formatDA","hash":{},"data":data}))
    + " "
    + alias3((helpers.formatTM || (depth0 && depth0.formatTM) || alias2).call(alias1,(depth0 != null ? depth0.studyTime : depth0),{"name":"formatTM","hash":{},"data":data}))
    + "</div>\n    </div>\n\n    <div class=\"load-progress-content\"></div>\n\n    <div class=\"bottomleft dicomTag\">\n        <div id=\"seriesNumber\">Ser: "
    + alias3(((helper = (helper = helpers.seriesNumber || (depth0 != null ? depth0.seriesNumber : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"seriesNumber","hash":{},"data":data}) : helper)))
    + "</div>\n        <div id=\"instanceNumber\">Img: "
    + alias3(((helper = (helper = helpers.instanceNumber || (depth0 != null ? depth0.instanceNumber : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"instanceNumber","hash":{},"data":data}) : helper)))
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.numImages : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n        <div id=\"dimensions\">"
    + alias3(((helper = (helper = helpers.dimensions || (depth0 != null ? depth0.dimensions : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"dimensions","hash":{},"data":data}) : helper)))
    + "</div>\n        <div id=\"seriesDescription\">"
    + alias3(((helper = (helper = helpers.seriesDescription || (depth0 != null ? depth0.seriesDescription : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"seriesDescription","hash":{},"data":data}) : helper)))
    + "</div>\n    </div>\n\n    <div class=\"bottomright dicomTag\">\n        <div id=\"zoomLevel\">"
    + alias3(((helper = (helper = helpers.zoomLevel || (depth0 != null ? depth0.zoomLevel : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"zoomLevel","hash":{},"data":data}) : helper)))
    + "</div>\n        <div id=\"compression\">"
    + alias3(((helper = (helper = helpers.compression || (depth0 != null ? depth0.compression : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"compression","hash":{},"data":data}) : helper)))
    + "</div>\n        <div id=\"windowLevel\">"
    + alias3(((helper = (helper = helpers.windowLevel || (depth0 != null ? depth0.windowLevel : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"windowLevel","hash":{},"data":data}) : helper)))
    + "</div>\n    </div>\n</div>";
},"useData":true});