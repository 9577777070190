var Handlebars = require("/Users/ayselafsar/Workspace/dicomviewer/js/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div id=\"AppDicomViewer\" class=\"app-dicomviewer\">\n    <div class=\"viewerDialogs\">\n"
    + ((stack1 = container.invokePartial(require("/Users/ayselafsar/Workspace/dicomviewer/js/components/templates/annotationDialogs.html"),depth0,{"name":"annotationDialogs","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n"
    + ((stack1 = container.invokePartial(require("/Users/ayselafsar/Workspace/dicomviewer/js/components/templates/ViewerMain.html"),depth0,{"name":"ViewerMain","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});